import { Component } from "solid-js";
import Main from "~/components/Main";
import PickTableComponent from "~/components/PickTable";
import styles from "./pick-table.module.css";
import * as TablesApi from "~/server/tables";
import { cache, createAsync } from "@solidjs/router";
import { Meta, Title } from "@solidjs/meta";

const getTablesByArea = cache(TablesApi.getTablesByArea, "tablesByArea");

export const route = {
  load: () => getTablesByArea(),
};

const PickTable: Component = (props) => {
  const tablesByArea = createAsync(() => getTablesByArea());

  return (
    <Main title="Tavoli" {...props} backLink="/app/menu">
      <Title>Selezione tavolo</Title>
      <Meta name="description" content="Selezione tavolo" />
      <div class={styles.pickTable}>
        <PickTableComponent
          tablesByArea={tablesByArea()}
          action={{ link: true }}
        />
      </div>
    </Main>
  );
};

export default PickTable;
